import React, { useEffect, useState } from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { fetchUrl } from '../../apiClient/baseApi';
import { Container, Row, Col } from "reactstrap";
import Layout from "../Layout"
import Loginstyles from '../../styles/LoginStyles';
import EditorStyles from '../../styles/EditorStyles';
import { BsPencil } from "react-icons/bs";
import defaultProfile from '../../assets/images/default_profile.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PricingPage from '../PricingPage';
import EditProfile from "../EditProfile";
import ReactModal from 'react-modal';
import Loader from '../Loader';

const toastr = require('toastr')


const Account = () => {
  const { t } = useTranslation();

  const [userLoaded, setUserLoaded] = useState(false)
  const [load, setLoad] = React.useState(false)
  const [user, setUser] = useState([]); 
  const [avatar, setAvatar] = useState(defaultProfile)

  useEffect( ()=>{
    if (!userLoaded) {
      setLoad(true)

      fetchUrl('get', '/users/me?fields=*.*').then((res) => {
      //let usr = JSON.parse(window.localStorage.getItem('user'))
      let usr = res.data;
      setUser(usr);

      if (usr.avatar) {
        let aid
        if (typeof usr.avatar == "object") {
             aid = usr.avatar["id"]
        }
      else {
        aid = usr.avatar
      }
        fetchUrl('assets', aid).then( res => {
          // Create an object URL from the data.
        let avatarSrc = URL.createObjectURL(res)
        setAvatar(avatarSrc)  
        })
      }
      
      setUserLoaded(true);
      setLoad(false)

      window.localStorage.setItem('user', JSON.stringify(usr));

    })

    }
    
}, [userLoaded])


const makeProfileClick = function() {
  const profile = document.getElementById('profileImg')
  profile.click()
}

const refreshUser = function() {
 fetchUrl('get', '/users/me?fields=*.*').then((res) => {
    window.localStorage.setItem('user', JSON.stringify(res.data));
    setUser(res.data)  
  })
}

const uploadImage = function(e) {
  const newImg = e.target.files[0]
    if (newImg) {
      const img = new Image()
      img.onload = async function () {
        console.log('image onload')
        if ((newImg.type === 'image/png' || newImg.type === 'image/jpg' || newImg.type === 'image/jpeg')) {
          const file = new FormData()
          file.append('file', newImg)

          await fetchUrl('post',  '/files', file, 'multipart/form-data').then(async res => {
            console.log('File uploaded to server')
            let data = { "avatar" : res.data.data.id }

            await fetchUrl('patch', '/users/me', data).then(() => {
              img.className='uploaded-book-cover' 
              img.id="avatar"
              const oldnode = document.getElementById('avatar')
              document.getElementById('image-grid').replaceChild(img, oldnode)
              console.log('updated the ui')
              fetchUrl('get', '/users/me?fields=*.*').then((res) => {
                window.localStorage.setItem('user', JSON.stringify(res.data));
              })
            })
          })
        } else {
          toastr.error('Please upload png/jpg/jpeg and try again')
        } 
      }
      img.onerror = function () {
        toastr.error('not a valid file: ' + newImg.type)
      }
      img.src = URL.createObjectURL(newImg)
    }
  }

  const cr8PortalSess = function() {
    setLoad(true)

    let data = {'customer_id' : user.stripe_customer_id}
    fetchUrl('post', "/create-portal-session", data)
    .then(res => {
        setLoad(false)
        window.open(res.stripeUrl, "_self");
      });

      setLoad(false)
  }


  return (<>
    { userLoaded ?
    <Layout>
      <Loginstyles>
      <div id="view-profile" className="login-screen" style={{textAlign: "center", padding: "70px 0"}}>
        <Container>
        <Row>
        <Col lg="6" md="6" className="bg-white" style={{width: "50%"}}> 
        <Row>
        <Col md="11">
        <div className='upload-book-cover-box' title='Click to Upload Profile Picture'>
            <input type='file' id='profileImg' accept='image/*' 
              onChange={uploadImage} />
            <div onClick={makeProfileClick} onKeyDown={makeProfileClick} role="presentation" id="image-grid">
              <img className='uploaded-book-cover' alt='Profile Avatar' src={avatar} id="avatar" />
              </div>
          </div> 
            <p> </p>
            <h3> {user.first_name} {user.last_name} </h3>
            <p style={{color: "grey"}}> { user.email } </p>
             {(user.role["name"] === 'Authenticated') ? <p><span style={{color: "grey"}}> {t('acc1')}: </span> FREE </p> : <p><span style={{color: "grey"}}> {t('acc1')}: </span> {user.role["name"].toUpperCase()} </p>}
             {user.description ? <p> {user.description.substring(0, 350)}... </p>: ''} 
             {user.pref_lang ? <p><span style={{color: "grey"}}> {t('acc3')}: </span> {user.pref_lang["name"]} </p> : ''}
             {user.pref_curr ? <p><span style={{color: "grey"}}> {t('acc4')}: </span> {user.pref_curr.toUpperCase()} </p> : ''} 
           </Col> 
           <Col md="1">
            <div id="editIcon" style={{paddingTop: "10px"}}>
              <BsPencil size="25px" onClick={() => {
				   var modal = document.getElementById("profileModal");
				   modal.style.display = "block";
				  }} style={{ cursor: 'pointer' }}/>
            </div>
          </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" className="bg-white">
          <Container>
            <br />
{/*             { (userLoaded && (user.role["name"] === 'Super')) ? 
            <Row>
              <button className="fb-btn" >Connect to Stripe</button>
            </Row>
            : <Row>
                <button className="fb-btn" onClick={() => {
				   var modal = document.getElementById("proModal");
				   modal.style.display = "block";
				}}>Connect to Stripe</button>
              </Row>  
            } 
            <br /> */}
            { (user.role["name"] === 'Super') ?
            <Row>
              <button className="ma-btn" onClick={() => cr8PortalSess()} >{t('acc5')}</button>
            </Row> :
            <Row>
              <button className="ma-btn" onClick={() => {
				   var modal = document.getElementById("proModal");
				   modal.style.display = "block";
				}}>{t('acc2')}</button>
            </Row> }
            <br />
            </Container>
        </Col>
        </Row>
        </Container>
        </div>
        
      </Loginstyles>   
      <EditorStyles>
      <div id="proModal" className="react-confirm-alert-overlay" role="presentation" onClick={(event) => {
                      let modl = document.getElementById("proModal");
                      if (event.target === modl) {
                          modl.style.display = "none";
                        }
                  }}>
                      <div className="react-confirm-alert-body promodal-content">
                          <span className="remove" role="presentation" onClick={() => {
                              let modl = document.getElementById("proModal");
                              modl.style.display = "none";
                          }}>&times;</span>
                          <p>{t('acc2')}</p>
                          <PricingPage /> 
                      </div>
          </div>
          <div id="profileModal" className="react-confirm-alert-overlay" role="presentation" onClick={(event) => {
                      let modl = document.getElementById("profileModal");
                      if (event.target === modl) {
                          modl.style.display = "none";
                        }
                  }}>
                      <div className="react-confirm-alert-body promodal-content">
                          <span className="remove" role="presentation" onClick={() => {
                              let modl = document.getElementById("profileModal");
                              modl.style.display = "none";
                          }}>&times;</span>
                          <EditProfile refreshUser={refreshUser}/> 
                      </div>
          </div>
      </EditorStyles>
    </Layout> : <p>User loading...</p> }
    <ReactModal 
        isOpen={load}
        contentLabel="Content Loading"
        onRequestClose={()=> {setLoad(false)}}
        className="react-confirm-alert-body"
        overlayClassName="react-confirm-alert-overlay"
        ariaHideApp={false}
    >
      <Loader />
    </ReactModal>
    </>
    )
  }

export default Account 
