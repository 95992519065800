import React from 'react';
import { BiMoveVertical } from "react-icons/bi";
import { BsPlusSquare } from "react-icons/bs";
import { Col } from 'reactstrap';
import ReactModal from 'react-modal';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Cr8Chapter from './cr8chapter';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { fetchUrl } from '../apiClient/baseApi';

   const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
   }

   const initialModalState = {
    showModal: false,
    chpTitle: "",
    chpType: "chapter",
   }

    export default function DragChapters (props) {
      const { t } = useTranslation();
    const [list, setList] = React.useState(props.children);
    const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);
    const [state, setState] = React.useState(initialModalState);
    
    // onDragStart fires when an element
    // starts being dragged
    const onDragStart = (event) => {
     const initialPosition = Number(event.currentTarget.dataset.position);
     
     setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list
     });
     
     
     // Note: this is only for Firefox.
     // Without it, the DnD won't work.
     // But we are not using it.
     event.dataTransfer.setData("text/html", '');
    }
    
    // onDragOver fires when an element being dragged
    // enters a droppable area.
    // In this case, any of the items on the list
    const onDragOver = (event) => {
     
     // in order for the onDrop
     // event to fire, we have
     // to cancel out this one
     event.preventDefault();
     
     let newList = dragAndDrop.originalOrder;
    
     // index of the item being dragged
     const draggedFrom = dragAndDrop.draggedFrom; 
   
     // index of the droppable area being hovered
     const draggedTo = Number(event.currentTarget.dataset.position); 
   
     const itemDragged = newList[draggedFrom];
     const remainingItems = newList.filter((item, index) => index !== draggedFrom);
   
      newList = [
       ...remainingItems.slice(0, draggedTo),
       itemDragged,
       ...remainingItems.slice(draggedTo)
      ];
       
     if (draggedTo !== dragAndDrop.draggedTo){
      setDragAndDrop({
       ...dragAndDrop,
       updatedOrder: newList,
       draggedTo: draggedTo
      })
     }
   
    }

    const handleOpenModal = () => {
        setState({ showModal: true });
    }

    const handleCloseModal = () => {
        setState({ showModal: false });
    }

    const addnewChp = async (newchp) => {
      let title = newchp.chpTitle
      if (title === "") {
        title = "  "
      }
      let weight = list[list.length - 1].weight + 1
      
      var data = { "bid": props.bid, "title": title, "type": newchp.chpType, "body": "<h1>" + title + "</h1>", "weight": weight }
      await fetchUrl('post',  '/items/chapters', data).then((res) => {
        
        let newList = [
            ...list, 
            {"id": res.data.id, "title": res.data.title, "type": res.data.type, "body": res.data.body, "weight": res.data.weight}
        ]
        setList(newList);
        setState({ showModal: false });
        props.newchpfn(newList, res.data.id);
      })
    }

    const removefromBook = async (chpid) => {
        await fetchUrl('delete',  '/items/chapters/' + chpid).then(() => {
                    let newList = list.filter(c => c.id !== chpid);
                    setList(newList);
                    props.newchpfn(newList, newList[0].id);
         })
      }

    const chooseChp = (chpid) => {
        props.chpid(chpid);
    }
      
    const onDrop = (event) => {
     let newList = dragAndDrop.updatedOrder

     newList.forEach(function(part, index) {
      this[index].weight = index;
    }, newList);

     setList(newList);

    Promise.all(
      newList.map( async (item) => {
        let data = { "weight" : item.weight }
        await fetchUrl('patch',  '/items/chapters/' + item.id, data)
      })
    ).then(() => {
      console.log('patched')
       props.newchpfn(newList, newList[dragAndDrop.draggedTo].id);
     })
     
     setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
     });
    }
   
   
    const onDragLeave = () => {
      setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null
     });
     
    }
    const buttonStyle = {
        margin: '1rem',
        fontSize: 16
      }
    const buttonStyle1 = {
        margin: '1rem',
        background: '#FF5B39',
        fontSize: 16
        }

    // Not needed, just for logging purposes:
    React.useEffect( ()=>{
     console.log("Dragged From: ", dragAndDrop && dragAndDrop.draggedFrom);
     console.log("Dropping Into: ", dragAndDrop && dragAndDrop.draggedTo);
    }, [dragAndDrop])
    
    React.useEffect( ()=>{
     console.log("List updated!");
     console.log(list);
    }, [list])

       return(
           <section>
             <div id="newCtnt">
                         <button onClick={handleOpenModal}>
                         {t('app15')}  <BsPlusSquare />
                      <span className="ck-tooltip">{t('app18')}</span>
                    </button> 
               </div>
                     <ReactModal 
                        isOpen={state.showModal}
                        contentLabel="Add New Section"
                        onRequestClose={handleCloseModal}
                        className="react-confirm-alert-body"
                        overlayClassName="react-confirm-alert-overlay"
                        appElement={document.getElementById('newCtnt') || undefined}
                    >

                    <Cr8Chapter addnewChp={addnewChp} close={handleCloseModal} chpTypes={props.chpTypes}/>
                    </ReactModal>
      <ul id="sideBar">
       
       {list ? list.map( (item, index) => {
        return(
         <li 
          key={index}
          role="presentation"
          
          data-position={index}
          draggable
          
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          
          onDragLeave={onDragLeave}
          
          className={(dragAndDrop && dragAndDrop.draggedTo=== Number(index) ? "dropArea" : "") + 
          (item.id === props.actChpId ? " active_item" : "")}
          >
           <span><BiMoveVertical size="30px"/></span>
           <Col onClick={() => chooseChp(item.id, item.title)}>
               <p>&nbsp;{item.title} <br /><em>&nbsp;{props.chpTypes[item.type]}</em></p>
            </Col>
  <button className="remove" title={`Remove ${item.title} from the book`}
  onClick = {() => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='react-confirm-alert-body'>
              <h1>{t('home19')}</h1>
          <p>{t('home20')} "{item.title}"?</p>
          <div className="react-confirm-alert-button-group">
              <button style={buttonStyle} onClick={onClose}>{t('home21')}</button>
              <button style={buttonStyle1}
                onClick={() => {
                    removefromBook(item.id);
                  onClose();
                }}
              >
                {t('home22')}
              </button>
              </div>
            </div>
          );
        }
      });
    }
}
  >&times;</button>
         </li>
        )
       }) : ''}
        
      </ul>
           </section>
           )
   };
   
