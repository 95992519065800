import styled from 'styled-components';

const SideNavStyles = styled.nav`

section {
  background: white;
  border-radius: 0.5em;
  box-shadow: 4px 4px 50px rgba(#FF5B39, 0);
  transition: box-shadow 100ms linear;
  &:hover {
   box-shadow: 4px 4px 50px rgba(#FF5B39, 0.6);
  }
  @media print {
    display: none;
 }
 }
 
 #sideBar {
  overflow-y: scroll;
  height: 84.3vh;	
 }

 #newCtnt {
  padding: 0.3em;
  height: 40px;
  background: var(--ck-color-toolbar-background);
  border: 1px solid var(--ck-color-toolbar-border);
  width: 100%;
}

#newCtnt button {
  background: var(--ck-color-toolbar-background);
  border: none;
  width: 100%;
  height: 100%;
  font: normal normal normal var(--ck-font-size-base)/var(--ck-line-height-base) var(--ck-font-face);
  color: black;
}

#newCtnt button:hover {
  background: var(--ck-color-button-default-hover-background);
}

#newCtnt .ck-tooltip {
  display: none;
  transform: translateY(180%); 
  position: absolute;
  top: -3px;
  left: 70px;
  width: 125px;
  z-index: 100;
  background: #000;
  color: #fff;
}

#newCtnt .ck-tooltip:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 50px;
  top: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

#newCtnt:hover .ck-tooltip {
  display: block; 
}

ul {
  list-style: none;
  padding-inline-start: 0.5em;
  padding-inline-end: 0;
  border-top: 1px solid lightgrey;
  &.dropArea {
    color: white !important;
    background: white !important;
    position: relative;
 
    &::before {
     content: "Drop Here";
     color: #FF5B39;
     font-size: 0.5em;
     text-transform: uppercase;
     width: 100%;
     height: 100%;
     border: 2px dashed #FF5B39;
     border-radius: 3px;
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
    }
  }


 li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;

  span {
    display: none;
   }
  

  &:hover {
    background: mediumpurple;
    color: white;
    cursor: pointer;
  
    span {
     display: block;
    }
   }
}
 p {
  max-width: none;
  margin: 0;
}

 button.remove {
  background: none;
  border:none;
  color: var(--orange);
  font-size: 3rem !important;
  top: 0;
  right: 0;
  box-shadow: none;
  line-height: 1rem;
  display: none;
}

 li:hover button.remove {
  display:block;
}

.active_item {
  background: lavender;
}

.react-icons {
  vertical-align: middle;
}

.siteStyl {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4, h5, h6 {
    font-size: 19px;
  }


  a {
    text-decoration: none;
    color: #FF5B39;
  }

  a:hover {
    text-decoration: none;
    color: #FF5B39;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Bebas Neue";
  }

  p, a {
    font-family: "Work Sans";
  }

}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-body {
  text-align: start !important; 
}
`;

export default SideNavStyles;