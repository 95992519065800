import styled from 'styled-components';
const BookSettingStyles = styled.nav`

body {
 background: #1d1e21;
 font-size: 3.5vw;
 @media (min-width: 900px) {
  font-size: 2vw;
 }
}

.upload-book-cover-box {
  border: 1px solid #e6ecf5;
  position: relative;
  height: 400px;
  width: 260px;
  overflow: hidden;
  margin-top: 1em;
  margin-bottom: 1em;
}


.uploaded-book-cover {
  max-height: 400px;
  overflow: hidden;
  cursor: pointer;
}

.upload-book-cover-box:hover {
  filter: blur(1px);
}

.upload-book-cover-box input {
  display: none;
}

.upload-book-cover-caption {
  font-weight: 500;
  text-align: center;
  top: 130px;
  padding: 5px;
  position: absolute;
  opacity: 0.5;
}

section {
 width: 550px;
 background: white;
 padding-top: 0.5em;
 color: black;
 border-radius: 0.5em;
 box-shadow: 4px 4px 50px rgba(#687bf7, 0);
 transition: box-shadow 100ms linear;
 &:hover {
  box-shadow: 4px 4px 50px rgba(#687bf7, 0.6);
 }


 #bookTitle1 {
  max-width: 430px;
  overflow: hidden;
  height: 33px;
  white-space: nowrap;
  &:hover {
    outline: 1px ridge white;
   }
  &:focus-visible {
    outline: 1px auto white;
  }
   font-weight: bold;
   font-size: 1.5rem;
   padding: 0px;
   text-align: start;
 }

 hr {
   margin-top: 0.3em;
   margin-bottom: 0.3em;
   color: grey;
 }

 .custom-txt {
  word-wrap: break-word;
  text-align: start;
  width: 350px;
  line-height: normal;
  white-space: normal;
 }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4, h5, h6 {
    font-size: 19px;
  }


  a {
    text-decoration: none;
    color: #FF5B39;
  }

  a:hover {
    text-decoration: none;
    color: #FF5B39;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Bebas Neue";
  }

  p, a {
    font-family: "Work Sans";
  }

}

`;

export default BookSettingStyles;