import React from 'react'
import Layout from '../Layout';
import { Container } from "reactstrap";


export default function Dashboard () {

  return (
  <Layout>
    <Container>
    <h1>Dashboard</h1>
    <p>This is a protected Dashboard for SUPER subscribers</p>
    </Container>
  </Layout> 
   )
  }
