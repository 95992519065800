import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Router } from "@reach/router"
import Account from "../components/app/Account"
import BookWizard from "../components/app/BookWizard"
import Dashboard from "../components/app/Dashboard"
import useAuth from "../hooks/useAuth"

const App = ({ location }) => {
  const { isAuthenticated } = useAuth()
  const { navigate } = useI18next()

  useEffect(() => {
    const loc_str = location.pathname.split('/')
    const bw_chk = loc_str[loc_str.length -2]
    const redirect = loc_str.pop()
    if (!isAuthenticated) {
      if(bw_chk === 'bookwizard' || redirect === 'bookwizard')
      {
        navigate('/login');
      }
      else {
        navigate('/login', { state: { redirect }});
      }
    }
  }, [isAuthenticated, location]);

  return (
      <div>
      { location.pathname.split('/')[1] === 'app' ? 
      <Router basepath="/app">
        <Account path="/account" />
        <BookWizard path="/bookwizard/:id" />
        <Dashboard default />
      </Router> :
      <Router basepath="/:lang/app">
        <Account path="/account" />
        <BookWizard path="/bookwizard/:id" />
        <Dashboard default />
      </Router> }
      </div>
  )
}
export default App

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "app", "common", "acc", "pricing"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;