import styled from 'styled-components';

const EditorStyles = styled.div`
/* avoid page breaks in tables, lists, images, and code snippets */
@media print {
    img {
        display: block;
        border: none;
    }
    img, table, ul, ol, .code-snippet, p {
        page-break-inside: avoid;
    }
    body, p {
        color: black;
    }
    a {
        text-decoration: none;
        color: black;
    }
    a img {
        border: none;
    }
    .entry a:after {
        content: " [" attr(href) "] ";
        font-size: smaller;
        text-decoration: none;
        color: black;
    }
     h1 { page-break-before : right }
	 h2 { page-break-after : avoid }

	 /* add the actual page break and remove the border */
	 .page-break {
		 page-break-after: always;
	 }
	 /* ignore the actual text */
	 .page-break div {
		 display: none !important;
	 } 

	 .row-editor .editor {
		padding: 1.75cm 1.5cm !important;
		margin: 0 !important;
		border: none !important;
		box-shadow: none !important;
		width: 100% !important;
	}

	.row-editor {
		border: none !important;
	}

	#toolBar {
		display: none;
	} 

}

@media projection, screen {
	/* add spacing to page break and draw the dotted line */
	.page-break {
		border-top: 1px dotted #b2b2b2;
		text-align: center;
		position: absolute;
		z-index: 10;
	}
	/* move the text up to center on the line */
	.page-break div {
		margin-top: -8px;
	}
	/* define the style for the text--including a white background */
	.page-break div span {
		background-color: #fff;
		color: #b2b2b2;
		padding: 0 5px;
		font: 10pt Arial,Helvetica,sans-serif;
	}
	h1, h2, h3, h4, h5, h6, p {
		hyphens: auto;
	}
}
.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */

main .ck-editor[role='application'] {
	overflow: auto;
}

.ck.ck-editor__editable {
	width: 100%;
}

/* Because of sidebar position: relative, Edge is overriding the outline of a focused editor. */
.ck.ck-editor__editable {
	position: relative;
	z-index: var(--ck-sample-editor-z-index);
}

.editor-container {
	display: block;
	/* flex-direction: row;
    flex-wrap: nowrap; */
    position: relative;
	width: 100%;
	justify-content: center;
	margin-top: 2.5rem;
}

/* --------- DECOUPLED (DOCUMENT) BUILD. ---------------------------------------------*/
 .document-editor__toolbar {
	width: 100%;
}

 .collaboration-demo__editable,
 .row-editor .editor {
	width: 8.27in;
	height: 100%;
	margin: auto;
	min-height: 11.69in;
	/* min-height: 26.19cm;
	 min-height: 24.69cm;
	padding: 1.75cm 1.5cm; */
	padding-left: 2.6cm;
	padding-right: 2.6cm;
	border: 1px hsl( 0, 0%, 82.7% ) solid;
	background-color: white;
	box-shadow: 0 0 5px hsla( 0, 0%, 0%, .1 );
	background-image: repeating-linear-gradient(
		transparent,
		transparent 10.35in,
		lightgrey 10.35in,
		whitesmoke 10.38in
	  );
	  background-attachment: local; 
}

#toolBar {
}

#txtFrame {
	overflow-y: scroll;
	height: 84.3vh;	
	margin-left: 1px;
	margin-right: 1px;
}

 .row-editor {
	display: block;
	position: relative;
	justify-content: center;
	overflow-y: auto;
	background-color: #f2f2f2;
	border: 1px solid hsl(0, 0%, 77%);
}

 .sidebar {
	background: transparent;
	border: 0;
	box-shadow: none;
}

.centered {
	/* Hide overlapping comments. 
	overflow: hidden; */
	max-width: var(--ck-sample-container-width);
	margin: 0 auto;
	padding: 0 var(--ck-sample-base-spacing);	
	bottom: 50px;
}

.row {
	display: flex;
	position: relative;
}

/* Pagination view line must be stacked at least at the same level as the editor,
   otherwise it will be hidden underneath. */
.ck.ck-pagination-view-line {
	z-index: var(--ck-sample-editor-z-index);
}

/* Move the square with page number from the Pagination plugin to the left side,
   so that it does not cover the sidebar. */
.ck.ck-pagination-view-line::after {
	transform: translateX(-100%);
	left: -1px;
	right: unset;
}

.ck-focused, .ck-blurred {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.editor-title .ck-focused, .editor-title .ck-blurred,
.editor-title .ck.ck-editor__editable_inline {
	text-align: center;
}

.editor-chapter .ck-focused, .editor-chapter .ck-blurred,
.editor-chapter .ck.ck-editor__editable_inline {
	margin-top: 40%;
}

.editor-part .ck-focused, .editor-part .ck-blurred,
.editor-part .ck.ck-editor__editable_inline {
	margin-top: 40%;
}

.editor-dedication .ck-focused, .editor-dedication .ck-blurred,
.editor-dedication .ck.ck-editor__editable_inline {
	font-style: italic;
	margin-top: 33%;
	text-align: center;
}

.ck.ck-dropdown .ck-dropdown__arrow {
	z-index: 0
  }

  #proModal, #profileModal, #pubModal {
	display: none; /* Hidden by default */
	overflow: auto;
  }
  
  /* Modal Content */
  .promodal-content {
	margin: 10% auto;
	padding: 20px;
	width: 50%;
	color: black;
	text-align: unset;
  }
  
  .pubmodal-content {
	margin: 5% auto;
	padding: 30px;
	width: 50%;
	color: black;
	text-align: unset;
  }

  span.remove {
    color: #ff5b39;
    font-size: 3rem !important;
    cursor: pointer;
    margin-top: -70px;
    margin-right: -50px;
}

[dir=rtl] span.remove {
	float: left;
}

[dir=ltr] span.remove {
	float: right;
}

  /* The Close Button */
  .close {
	color: #aaaaaa;
	font-size: 28px;
	font-weight: bold;
  }

  [dir=rtl] .close {
	float: left;
  }

	[dir=ltr] .close {
		float: right;
  }

  
  .close:hover,
  .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
  }


button {
    width: 100%;
    border-radius: 0.3em;
}

.ma-btn {
    background-color: mediumpurple;
	border: none;
	color: white;
    box-shadow: 0 2px 0 purple;
  
    &:hover {
      background-color: #7D59C2;
    }
  }

  .siteStyl {
	h1 {
	  font-size: 40px;
	}
  
	h2 {
	  font-size: 28px;
	}
  
	h3 {
	  font-size: 24px;
	}
  
	h4, h5, h6 {
	  font-size: 19px;
	}
  
  
	a {
	  text-decoration: none;
	  color: #FF5B39;
	}
  
	a:hover {
	  text-decoration: none;
	  color: #FF5B39;
	}
  
	h1, h2, h3, h4, h5, h6 {
	  font-family: "Bebas Neue";
	}
  
	p, a {
	  font-family: "Work Sans";
	}
  
  }
  .react-confirm-alert-overlay {
	background: rgb(103 103 103 / 90%) !important;
  }

  .react-confirm-alert-button-group {
	justify-content: center !important;
  }
  
`;

export default EditorStyles;