import React, { useState, useEffect, useRef } from 'react';
/* import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from 'ckeditor5-custom-build/build/ckeditor'; */
import EditorStyles from '../styles/EditorStyles';
import { CloudinaryUnsigned } from 'puff-puff/CKEditor';
import { fetchUrl } from '../apiClient/baseApi';
import PricingPage from '../components/PricingPage';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const cdnName = process.env.GATSBY_CDN_NAME
const cdnPreset = process.env.GATSBY_CDN_PRESET

export default function TextEditor( props ) {
    const { t } = useTranslation();

    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { CKEditor, DecoupledEditor } = editorRef.current || {}
    const [toolBar, setToolBar] = useState(['heading',  '|', 'fontSize', 
    'fontPro',
    '|', 'bold', 'italic', 'link', 'subscript', 
    'superscript', '|', 'numberedList', 'bulletedList', '|', 
    'code', 'codeBlock', '|', 'insertTablePro', 'specialCharacters', '|', 'uploadImage', 'blockQuote', '|', 
    'horizontalLine', '|', 'undo', 'redo'])

  
    useEffect(() => {

      let user = JSON.parse(window.localStorage.getItem('user'))
      if(user && (user.role["name"] === 'Pro' || user.role["name"] === 'Super')) {
        let newToolBar = [ 'heading',  '|', 'fontSize', 
        'fontFamily',
        '|', 'bold', 'italic', 'link', 'subscript', 
        'superscript', '|', 'numberedList', 'bulletedList', '|', 
        'code', 'codeBlock', '|', 'insertTable', 'specialCharacters', '|', 'uploadImage', 'blockQuote', '|', 
        'horizontalLine', '|', 'undo', 'redo' ]
        setToolBar(newToolBar)
      }
      editorRef.current = {
        CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
        DecoupledEditor: require('ckeditor5-custom-build/build/ckeditor')
      }
      setEditorLoaded(true)
    }, [])


        let incomingStory = props.children;
        let incomingChp = "chp"+props.chpid;
        let stickyValue = window.localStorage.getItem(incomingChp);
        let inStory = (stickyValue !== null && stickyValue !== "") ? JSON.parse(stickyValue) : incomingStory;
        let ediClass = 'editor ' + props.ediType

        const imagePluginFactory = function (editor) {

            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                let savEle = document.getElementById("savings");
                savEle.innerHTML = t('app12');
                let body = { "cloudimg_last_uploaded" : Math.floor(new Date().getTime() / 1000) }
                fetchUrl('patch',  '/items/books/' + props.bid, body).then(() => {
                    savEle.innerHTML = t('app13')
                })
              return new 
              CloudinaryUnsigned(
                loader,
                cdnName,
                cdnPreset,
                "books/" + props.bid
               //, [ 160, 500, 1000, 1052 ]
               );
            };

          }

        let fontOptions = []

        switch(props.langCode) {
            case 'ta': {
                 fontOptions = ['Pavanam',
                'Meera Inimai',
                'Kavivanar',
                'Catamaran',
                'Arima Madurai',
                'Mukta Malar',]
                break;
            }
            case 'ka': {
                 fontOptions = ['Baloo Tamma 2',
                'Akaya Kanadaka',
                'Benne',]
                break;
            }
            case 'hi': {
                 fontOptions = ['Baloo-2',
                'Asar',
                'Tillana',
                'Rozha One',
                'Amita',
                'Laila',
                'Karma',
                'Kalam',
                'Yantramanav',
                'Rajdhani',
                'Martel',
                'Teko',
                'Hind',
                'Mukta',
                'Poppins',]
                break;
            }
            case 'bn': {
                 fontOptions = ['Hind Siliguri',
                'Baloo Da 2',
                'Atma',
                'Galada',
                'Mina',]
                break;
            }
            default: {
                 fontOptions = ['default',
                'Roboto',
                'Bebas Neue',
                'Work Sans',
                'Inconsolata',
                'Arial',
                'Courier New',
                'Open Sans',
                'Georgia',
                'Lucida Sans Unicode',
                'Tahoma',
                'Times New Roman',
                'Trebuchet MS',
                'Verdana',]
                break;
            }
        }


        const editorConfiguration = {
            toolbar: toolBar,
            extraPlugins: [ imagePluginFactory ],
            fontFamily: {
                options: fontOptions
            },
            image: {
                toolbar: [
                    'imageTextAlternative'
                ]
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells'
                ]
            },
            language: {
                // The UI will be English.
                ui: 'en',
        
                // But the content will be edited in Arabic.
                content: props.langCode
            },
            title: {
                placeholder: ' '
            }
        };

        //Initiate a state for chpid and chpdata
        // when switching chapters, before accepting chpdata from parent, 
        // check if you've the data already stored in state, as parent data is stale
        return (
            <EditorStyles>
                <div className="centered">
                    <div id="toolBar" className="row">
                        <div className="document-editor__toolbar"></div>
                    </div>
                <div id="txtFrame" className="row row-editor">
                    <div className="editor-container">
                        <div className={ ediClass }>
                            { editorLoaded ? 
                            <CKEditor
                            editor={ DecoupledEditor }
                            config={ editorConfiguration }
                            data= { inStory }
                            onReady={ editor => {
                                // Insert the toolbar before the editable area.
                                const toolbarContainer = document.querySelector(  '.document-editor__toolbar' );
                                toolbarContainer.appendChild( editor.ui.view.toolbar.element );

                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onError={ ( { willEditorRestart } ) => {
                                // If the editor is restarted, the toolbar element will be created once again.
                                // The `onReady` callback will be called again and the new toolbar will be added.
                                // This is why you need to remove the older toolbar.
                                if ( willEditorRestart ) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            } }
                            onChange={ ( event, editor ) => {
                                console.log( 'Changed.', editor );
                            } }
                            onBlur={ ( event, editor ) => {
                                let data = editor.getData();
                                let savEle = document.getElementById("savings");

                                savEle.innerHTML = t('app12');
                                console.log( 'Blur.', data );
                                window.localStorage.setItem(incomingChp, JSON.stringify(data));
                                let body = { "body" : data }
                                fetchUrl('patch',  '/items/chapters/' + props.chpid, body).then(() => {
                                    savEle.innerHTML = t('app13')
                                })
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                                let savEle = document.getElementById("savings");

                                if(savEle.innerHTML === t('app13')) {
                                    savEle.innerHTML = t('app11');
                                }
                            } }
                            /> : <div>Editor loading...</div>
                        }
                        </div>
                    </div>
                 </div>
             </div>
             <div id="proModal" className="react-confirm-alert-overlay" role="presentation" onClick={(event) => {
                let modl = document.getElementById("proModal");
                 if (event.target === modl) {
                    modl.style.display = "none";
                  }
             }}>
                <div className="react-confirm-alert-body promodal-content">
                    <span className="close" role="presentation" onClick={() => {
                        let modl = document.getElementById("proModal");
                        modl.style.display = "none";
                    }}>&times;</span>
                    <p>{t('app16')}</p>
                    <PricingPage /> 
                </div>
            </div>
            </EditorStyles>
        );
}



