import styled from 'styled-components';

const WizardNavStyles = styled.nav`
.react-icons {
  vertical-align: middle;
}

#bookTitle {
  padding-right: 10px;
  padding-left: 10px;
  max-width: 430px;
  overflow: hidden;
  height: 33px;
  white-space: nowrap;
  &:hover {
    outline: 1px ridge white;
   }
  &:focus-visible {
    outline: 1px auto white;
  }
}
ul {
  margin: 0;
  padding: 10px 20px;
  text-align: center;
  align-items: center;
  list-style: none;
  display: grid;
  grid-gap:1.3rem;
  grid-template-columns: auto auto 1fr auto auto auto auto auto;
  background: linear-gradient(90deg, #FF5B39, #7D59C2);
  color: white;
  height: 50px;

  li {
    height: 35px;
    line-height: 33px;
  }
  li a, li button {
    display: inline-block;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  
  li button {
    background-color: transparent;
    border: none;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 40px;
  }
    
  .settings-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 40px;
  }

  .dropdown-content a, .settings-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: start;
  }
  
  .dropdown-content a:hover, .settings-content a:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .dropdown-toggle {
    display: block;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
.linkStyle1 {
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    background: #f98d76;
    color: white;
    cursor: pointer;
   }
}

.linkStyle2 {
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  width: 100%;
  text-align: start;
  display: block;
  background: white;
  &:hover {
    cursor: pointer;
    background: whitesmoke;
   }
}

.dropbtn {
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  background: whitesmoke;
  text-decoration: none;
  color: black;
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    background: white;
    cursor: pointer;
   }
}

hr {
  margin: 0;
  color: grey;
}

#navBar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  z-index: 1;
}

@media print {
  #navBar {
    display: none;
  }
 }

 #proModal, #profileModal, #pubModal {
	display: none; /* Hidden by default */
  overflow: auto;
  }
  
  span.remove {
    color: #ff5b39;
    font-size: 3rem !important;
    cursor: pointer;
    margin-top: -70px;
    margin-right: -50px;
}

[dir=rtl] span.remove {
	float: left;
}

[dir=ltr] span.remove {
	float: right;
}

.react-confirm-alert-body {
  text-align: start !important; 
}
`;

export default WizardNavStyles;