import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'gatsby-plugin-react-i18next';

const ChpStyles = styled.nav`

body {
 background: #1d1e21;
 font-size: 3.5vw;
 @media (min-width: 900px) {
  font-size: 2vw;
 }
}

section {
 background: white;
 padding: 0.5em;
 border-radius: 0.5em;
 box-shadow: 4px 4px 50px rgba(#687bf7, 0);
 transition: box-shadow 100ms linear;
 text-align: center !important;
 &:hover {
  box-shadow: 4px 4px 50px rgba(#687bf7, 0.6);
 }
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}


`;

class Cr8Chapter extends React.Component {

    state = {
      chpTitle: "",
      chpType: "chapter",
    };
  

   handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
   handleSubmit = event => {
    event.preventDefault()
  //  alert(`Welcome ${this.state.chpTitle} ${this.state.chpType}!`)
    this.props.addnewChp(this.state);
    //console.log(this.state);
  }
  render() {
    const { t } = this.props;

    const buttonStyle1 = {
      margin: '1rem',
      background: '#7D59C2',
      fontSize: 15
    }

    const buttonStyle = {
      margin: '1rem',
      fontSize: 15
    }

    let typesList = Object.keys(this.props.chpTypes).map((k) => {
      return (
        <option key={k} value={k}>{this.props.chpTypes[k]}</option>
      )
    }, this);
  

    return(

        <ChpStyles> 
        <section>
          <h2>{t('app14')}</h2>
        <form onSubmit={this.handleSubmit}>
        <label>
          <input
            style={buttonStyle} 
            type="text"
            name="chpTitle"
            value={this.state.chpTitle}
            onChange={this.handleInputChange}
            placeholder={t('link18')}
          />
        </label>
         
        <label >
          <select style={buttonStyle} name="chpType" onChange={this.handleInputChange} value={this.state.chpType}>
            {typesList}
          </select>
        </label>
        <div className="react-confirm-alert-button-group">
          <button style={buttonStyle1} type="submit">{t('link21')}</button>
          <button style={buttonStyle} onClick={this.props.close}>{t('link20')}</button>
        </div>
      </form>
        </section>
        </ChpStyles>

    );
  }
}

export default withTranslation()(Cr8Chapter);