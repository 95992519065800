// Step 1: Import React
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Container, Row, Col } from "reactstrap";
import LoginStyles from '../styles/LoginStyles';
import { fetchUrl } from '../apiClient/baseApi';
import useAuth from "../hooks/useAuth"

// Step 2: Define your component
const EditProfile = (props) => {
    const { t } = useTranslation();

    const [curr, setCurr] = useState('USD')
    const [planloaded, setPlanloaded] = useState(false)
    const planRef = useRef()
    const { isAuthenticated } = useAuth()
    const [user, setUser] = useState([]); 
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [biodata, setBiodata] = useState('')
    const [langOptions, setLangOptions] = useState([])
    const [lang, setLang] = useState('')


    let priceOptions = (<option value="USD">USD</option>)
    let planOptions = [{"name": "pro", "price": {"USD": 30}, "frequency" : "once", "discount": 0},
        {"name": "super", "price": {"USD": 20}, "frequency" : "yearly", "discount": 16}]
    const [currOptions, setCurrOptions] = useState(priceOptions);
    const { plans } = planRef.current || { plans : planOptions }

    useEffect(() => {
        const plan_tbl = process.env.GATSBY_PLANS

        fetchUrl('get', '/items/' + plan_tbl).then(res => {
            planRef.current = { plans : res.data }
            setPlanloaded(true)
        })
    }, []);


    useEffect(() => {
        if(planloaded) {
        const searchPlans = function (name) {
            let match = plans.find(list => list.name === name);
            
             if (name && match) {
               return (name && match)
             }
             else {
               return ""
             }
          }
        let price = searchPlans('pro').price
        let currList = Object.keys(price).map((k) => {
           return (
            <option key={k} value={k}>{k}</option> 
           )
         }, this); 
         setCurrOptions(currList)
        }
    }, [plans, planloaded]);


  useEffect( ()=>{
    if (isAuthenticated) {
        let usr = JSON.parse(window.localStorage.getItem('user'))
        setUser(usr);
        if (usr.first_name) {
          setFirstname(usr.first_name)
        }
        if (usr.last_name) {
          setLastname(usr.last_name)
        }
        if (usr.description) {
          setBiodata(usr.description)
        }
        if (usr.pref_curr) {
          setCurr(usr.pref_curr.toUpperCase());
        }
        if (usr.pref_lang) {
          let lid
          if (typeof usr.pref_lang == "object") {
               lid = usr.pref_lang["id"]
          }
        else {
          lid = usr.pref_lang
        }
          setLang(lid)
        }
        fetchUrl('get', '/items/language').then( res => {
            setLangOptions(res.data); 
          }) 
    }
   }, [isAuthenticated])

   const handleSubmit = async (event) => {
    event.preventDefault()
    var data = { }
    if (firstname) {
      data["first_name"] = firstname
    }
    if (lastname) {
      data["last_name"] = lastname
    }
    if (biodata) {
      data["description"] = biodata
    }
    if (curr) {
      data["pref_curr"] = curr
    }
    if (lang) {
      data["pref_lang"] = lang
    }
    if (Object.keys(data).length !== 0) {
      try {
        await fetchUrl('patch', '/users/me', data).then( async (res) => {
            props.refreshUser()
            document.getElementById('profileModal').style.display = 'none'
        })
      } catch(e){
        console.log("Error occurred while saving profile")
      }
    }
  }

  return (
        <LoginStyles>
                <Container>
          
      <form id="profile" className="edit-profile" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6" md="6">
                    <div style={{padding: "9px"}}>
                        <input placeholder={t('acc6')} type="text"  
                         onChange={ e => {
                          setFirstname(e.target.value)
                        }}
                        value={ firstname } />
                        </div>
                    </Col>
                    <Col lg="6" md="6">
                    <div style={{padding: "9px"}}>
                        <input placeholder={t('acc7')} type="text"  
                         onChange={ e => {
                          setLastname(e.target.value)
                        }}
                        value={ lastname } />
                    </div>
                    </Col>

                    </Row>
                    <Row>
                    <Col lg="12" md="12">
                      <div style={{padding: "15px"}}>
                        <textarea placeholder={t('acc8')} rows="4" style={{width: "100%"}}
                         onChange={ e => {
                          setBiodata(e.target.value)
                        }}
                        value={ biodata } />
                      </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col lg="6" md="6">
                    <label style={{padding: "15px"}}>
                    <select name="prefLang" onChange={e => {
                          setLang(e.target.value)
                        }}  value={lang}>
                      <option>{t('acc3')}</option>
                      {langOptions.map((e, key) => {
                                          return <option key={key} value={e.id}>{e.name}</option>;
                                      })}
                    </select>
                  </label>
                  </Col>
                  
                  <Col lg="6" md="6">
                  <label style={{padding: "15px"}}>
                  <select name="prefCurr" onChange={e => {
                          setCurr(e.target.value)
                        }}  value={curr}>
                      <option>{t('acc4')}</option>
                      {currOptions}
                    </select>
                  </label>
                  </Col>
                    </Row>
                      <div style={{padding: "15px"}}>
                      <button type="submit" className="ma-btn">{t('acc9')}</button>
                      </div>
                    </form> 
                    
                    </Container>
        </LoginStyles>
    )
}

// Step 3: Export your component
export default EditProfile
