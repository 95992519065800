import { createGlobalStyle } from 'styled-components';

const BWStyles = createGlobalStyle`

[dir=rtl] .dropdown-content {
    left: 0;
    right: unset;
}

[dir=ltr] .dropdown-content {
    right: 0;
    left: unset;
}

`;

export default BWStyles;
