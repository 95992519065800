// Step 1: Import your component
import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'reactstrap';
import WizardNavStyles from '../../styles/WizardNavStyles';
import BWStyles from '../../styles/BWStyles';
import SideNavStyles from '../../styles/SideNavStyles';
import EditorStyles from '../../styles/EditorStyles';
import TextEditor from '../TextEditor';
import { StaticImage } from "gatsby-plugin-image";
import DragChapters from '../ChaptersList';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BiRocket } from "react-icons/bi";
import { AiOutlineFilePdf, AiOutlineFileZip } from "react-icons/ai";
import { BsDownload, BsBook, BsBookHalf } from "react-icons/bs";
import BookSettings from '../BookSettings';
import { fetchUrl } from '../../apiClient/baseApi'
import useAuth from "../../hooks/useAuth"
import proIcon from "../../assets/images/Pro_logo.png"
import gPlayBooks from "../../assets/images/play_books.png"
import 'react-confirm-alert/src/react-confirm-alert.css';
import PricingPage from '../PricingPage';
import PubPage from '../PubPage';
import ReactModal from 'react-modal';
import Loader from '../Loader';

const toastr = require('toastr')

// Step 2: Define your component

export default function BookWizard({ location, id }) {
  const { navigate, t, i18n } = useI18next();

  const direc = i18n.dir();

  const [bookTitle, setBookTitle] = React.useState('');
  const [bookCoverSrc, setBookCoverSrc] = React.useState('');
  const [langCode, setLangCode] = React.useState(location.state.book.language.code);
  const [desId, setDesId] = React.useState('');

  const [chaptersLoaded, setChaptersLoaded] = React.useState(false)
  const [state, setState] = React.useState({});
  const [actChpId, setActChpId] = React.useState(0);
  const [chpTypes, setChpTypes] = React.useState({});
  const [user, setUser] = React.useState([]);
  const [load, setLoad] = React.useState(false)
  const { isAuthenticated } = useAuth()

  React.useEffect(() => {
    if (typeof window !== "undefined" || window.document !== null) {
      document.body.dir = i18n.dir();
    }
    let bid = 'book' + id;
    let tid = 'title' + id;

    if (!isAuthenticated) {
      navigate('/login');
    }
    else {
      setLoad(true)
      setUser(JSON.parse(window.localStorage.getItem('user')));
      if (location.state.book.canva_design_id !== null) {
        setDesId(location.state.book.canva_design_id)
      }

      let Chps = typeof window !== 'undefined' && window.localStorage.getItem(bid) !== null ?
        JSON.parse(window.localStorage.getItem(bid)) : location.state.book.chapters

      let initialState = {
        chps: Chps
      }

      setState(initialState)
      setActChpId(Chps[0].id)

      let btitle = typeof window !== 'undefined' && window.localStorage.getItem(tid) !== null ?
        window.localStorage.getItem(tid) : location.state.book.title

      setBookTitle(btitle)

      const loadChpTypes = async function () {
        await fetchUrl('get', '/items/chapter_types?filter={ "lang_code": { "_eq": "' + location.state.book.language.code + '" }}&fields=types').then((res) => {
          setChpTypes(res.data[0].types);

          setBookCoverSrc(location.state.book.cover)
          setLangCode(location.state.book.language.code)
        })
      }
      loadChpTypes();
    }

  }, [isAuthenticated, id, location])

  React.useEffect(() => {
    if (!chaptersLoaded && Object.keys(chpTypes).length > 0) {
      console.log(chpTypes)
      setChaptersLoaded(true)
      setLoad(false)
    }
    if (chaptersLoaded) {

      if (location.state.cover) {
        document.getElementById("btnSetng").classList.toggle("dropdown-toggle");
      }
    }
  }, [chpTypes, chaptersLoaded, location])

  function searchChp(chpId) {
    let match
    if (state.chps) {
      match = state.chps.find(list => list.id === chpId);
    }
    if (chpId && match) {
      return (chpId && match).body || "<h1>" + (chpId && match).title + "</h1>"
    }
    else {
      return ""
    }
  }

  function getChpType(chpId) {
    let match
    if (state.chps) {
      match = state.chps.find(list => list.id === chpId);
    }
    if (chpId && match) {
      return 'editor-' + (chpId && match).type
    }
    else {
      return ""
    }
  }

  function newchpfn(newList, chpId) {
    setState({ chps: newList });
    setActChpId(chpId);
    let bid = 'book' + id;
    window.localStorage.setItem(bid, JSON.stringify(newList));
  }

  function chgActId(chpId) {
    setActChpId(chpId);
  }

  function toggleBtn(ele) {
    var elist = ['btnDnld', 'btnStore', 'btnSetng'];
    elist.forEach(element => {
      if (element !== ele) {
        document.getElementById(element).classList.remove("dropdown-toggle");
      }
    });
    document.getElementById(ele).classList.toggle("dropdown-toggle");
  }

  function saveTitle(event) {
    console.log("saving book title")
    setBookTitle(event.target.textContent);
    document.getElementById('editIcon').style.display = "block";
    let tid = 'title' + id;
    window.localStorage.setItem(tid, event.target.textContent);
    let data = { "title": event.target.textContent }
    fetchUrl('patch', '/items/books/' + id, data)
  }

  const genEbook = async function (type) {
    let cover_check = document.getElementById("cover-image").alt;

    if (location.state.book.cover || cover_check === 'New Book Cover') {
      setLoad(true)
      await fetchUrl('get', '/generate-ebook/' + id + '/' + type).then((fileid) => {

        fetchUrl('get', '/assets/' + fileid + '?timestamp=' + Math.floor(new Date().getTime() / 1000), { responseType: 'arraybuffer' }).then(res => {
          let ftype
          if ((type === 'print1') || (type === 'print2') || (type === 'pdf')) {
            type = 'pdf'
            ftype = 'application/pdf'
          }
          else if (type === 'epub') {
            ftype = 'application/epub+zip'
          }
          // Create an object URL from the data.
          var file = new Blob([res], { type: ftype });
          let fileURL = URL.createObjectURL(file)

          // Download the file programmatically
          var a = document.createElement('a');
          a.href = fileURL
          a.target = "_blank"
          a.download = bookTitle + '.' + type
          a.click();
          setLoad(false)
        })
      })
    }
    else {
      toggleBtn("btnSetng")
      toastr.error('Please upload a cover page for the ebook and try again')
    }
  }


  function preventEnter(event) {
    if (event.which === 13) {
      event.preventDefault();
      event.currentTarget.blur();
    }
    return event.which !== 13;
  }

  const LinkStyle = {
    textDecoration: "none",
    color: "white",
  }


  return (
    <div>
      <BWStyles />
      <ReactModal
        isOpen={load}
        contentLabel="Content Loading"
        onRequestClose={() => { setLoad(false) }}
        className="react-confirm-alert-body"
        overlayClassName="react-confirm-alert-overlay"
        ariaHideApp={false}
      >
        <Loader />
      </ReactModal>
      {chaptersLoaded ? <>
        <WizardNavStyles>
          <ul id="navBar">
            <li>
              <Link to="/" style={LinkStyle}>
                <div className="linkStyle1">
                  {direc === 'ltr' ? <FaAngleLeft /> : <FaAngleRight />}&nbsp;{t('link1')}
                </div>
              </Link>
            </li>
            <li className="dropdown">
              <button className="linkStyle1" onClick={() => toggleBtn("btnSetng")}>
                {t('app3')}
              </button>
              <div id="btnSetng" className="settings-content" dir={direc}>
                <BookSettings bookTitle={bookTitle} bookCoverSrc={bookCoverSrc} saveTitle={saveTitle}
                  bid={id} preventEnter={preventEnter} tglbtn={toggleBtn} designId={desId} />
              </div>
            </li>
            <li style={{ textAlign: 'left' }}>
              <em id="savings" style={{ color: 'lightpink' }} >{t('app13')}</em>
            </li>
            <li>
              <div id="bookTitle" contentEditable="true" onBlur={saveTitle}
                onKeyPress={preventEnter}
                suppressContentEditableWarning role="presentation">
                {bookTitle}
              </div>
            </li>
            <li className="dropdown">
              <button className="dropbtn" onClick={() => toggleBtn("btnDnld")}>
                <BsDownload />&nbsp;{t('home12')}
              </button>
              <div id="btnDnld" className="dropdown-content" dir={direc}>
                <button className="linkStyle2" onClick={() => genEbook('epub')}><AiOutlineFileZip /> {t('home13')}</button>
                <hr />
                <button className="linkStyle2" onClick={() => genEbook('pdf')}><AiOutlineFilePdf /> {t('home14')}</button>
                <hr />
                {(user && (user.role["name"] === 'Pro' || user.role["name"] === 'Super')) ?
                  <><button className="linkStyle2" onClick={() => genEbook('print1')}><BsBookHalf /> {t('home15')}
                  </button>
                    <hr />
                    <button className="linkStyle2" onClick={() => genEbook('print2')}><BsBook /> {t('home16')}
                    </button></> :
                  <><button className="linkStyle2" onClick={() => {
                    var modal = document.getElementById("proModal");
                    modal.style.display = "block";
                  }}><BsBookHalf /> {t('home15')} <img src={proIcon} alt="Upgrade plan" title="Upgrade plan" style={{ height: "23px" }} /></button>
                    <hr />
                    <button className="linkStyle2" onClick={() => {
                      var modal = document.getElementById("proModal");
                      modal.style.display = "block";
                    }}><BsBook /> {t('home16')} <img src={proIcon} alt="Upgrade plan" title="Upgrade plan" style={{ height: "23px" }} /></button></>}
              </div>
            </li>
            <li className="dropdown">
              <button className="dropbtn" onClick={() => toggleBtn("btnStore")}>
                <BiRocket />&nbsp;{t('app1')}
              </button>
              {(user && (user.coupon_code || user.role["name"] === 'Super')) ?
                <div id="btnStore" className="dropdown-content" dir={direc}>
                  {/*  <button className="linkStyle2">Magic Author Store</button>
        <hr /> */}
                  <button className="linkStyle2" onClick={() => {
                    var modal = document.getElementById("pubModal");
                    modal.style.display = "block";
                  }}><img src={gPlayBooks} alt="Google Play Books" title="Google Play Books" style={{ height: "23px" }} /> {t('app2')}</button>
                </div> :
                <div id="btnStore" className="dropdown-content" dir={direc}>
                  {/* <button className="linkStyle2"  onClick={() => {
				   var modal = document.getElementById("proModal");
				   modal.style.display = "block";
				}}>Magic Author Store  <img src={proIcon} alt="Upgrade plan" title="Upgrade plan" style={{height: "23px"}} /></button>
      <hr /> */}
                  <button className="linkStyle2" onClick={() => {
                    var modal = document.getElementById("proModal");
                    modal.style.display = "block";
                  }}><img src={gPlayBooks} alt="Google Play Books" title="Google Play Books" style={{ height: "23px" }} /> {t('app2')}  <img src={proIcon} alt="Upgrade plan" title="Upgrade plan" style={{ height: "23px" }} /></button>
                </div>}
            </li>
          </ul>
        </WizardNavStyles>
        <EditorStyles>
          <div id="proModal" className="react-confirm-alert-overlay siteStyl" role="presentation" onClick={(event) => {
            let modl = document.getElementById("proModal");
            if (event.target === modl) {
              modl.style.display = "none";
            }
          }} dir={direc}>
            <div className="react-confirm-alert-body promodal-content">
              <span className="remove" role="presentation" onClick={() => {
                let modl = document.getElementById("proModal");
                modl.style.display = "none";
              }}>&times;</span>
              <p>{t('app16')}</p>
              <PricingPage />
            </div>
          </div>
          <div id="pubModal" className="react-confirm-alert-overlay siteStyl" role="presentation" onClick={(event) => {
            let modl = document.getElementById("pubModal");
            if (event.target === modl) {
              modl.style.display = "none";
            }
          }} dir={direc}>
            <div className="react-confirm-alert-body pubmodal-content">
              <span className="remove" role="presentation" onClick={() => {
                let modl = document.getElementById("pubModal");
                modl.style.display = "none";
              }}>&times;</span>
              <PubPage data={location.state.book} />
            </div>
          </div>
        </EditorStyles>
        <div style={{ marginTop: '50px', bottom: '50px', top: '0', position: 'fixed', width: '100%' }}>
          <Row>
            <Col md="2" style={{ paddingInlineEnd: '0' }}>
              <SideNavStyles>
                <DragChapters className="siteStyl" chpid={chgActId} actChpId={actChpId} bid={id} newchpfn={newchpfn} chpTypes={chpTypes}>
                  {state.chps}
                </DragChapters>
              </SideNavStyles>
            </Col>
            <Col md="8" style={{ paddingInline: '0' }}>
              <TextEditor chpid={actChpId} langCode={langCode} ediType={getChpType(actChpId)} bid={id}>
                {searchChp(actChpId)}
              </TextEditor>
            </Col>
            {/* <Col md="3"  style={{ paddingTop: '0.5em' }}>
  <p> This is a placeholder for the third column on the right most side of the page. </p>
</Col> */}
          </Row>
        </div>
        {/* <footer style={{position: 'fixed', bottom: '0', left: '0', height: '50px', width: '100%', border: '1px solid lightgrey', background: 'lavender'}}>
            <Container>
                <Row>    
                <Col md="1">
                    <StaticImage src="../assets/images/our_logo.png" alt="Magic Author" layout="fullWidth" />
                    </Col>
                    <Col md="3">           
                        <p>&copy; Magic Author <sup>&reg;</sup> {new Date().getFullYear()} </p>
                        <p>&nbsp;&nbsp;Registered in India & Australia</p>
                     </Col>
                     <Col md="3">
                         <Link to='/about-us'>About</Link>
                         <br />
                         <Link to='/pricing'>Pricing</Link>
                     </Col>
                     <Col md="3">
                         <Link to='/privacy-policy'>Privacy Policy</Link>
                         <br />
                         <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                     </Col>
                     <Col md="2">
                        <Link to='/latest-announcements'>Latest Announcements</Link>
                     </Col>
                </Row>
            </Container>
    </footer> */}
      </>
        : <>&nbsp;</>}
    </div>
  );
}
