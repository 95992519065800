import React from 'react';
import BookSettingStyles from '../styles/BookSettingStyles';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'reactstrap';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import bookCoverImg from '../assets/images/def-book-cover.png';
import { BsPencil, BsDownload } from "react-icons/bs";
import { FiHelpCircle } from "react-icons/fi";
import { BiLinkExternal } from "react-icons/bi";
import { fetchUrl } from '../apiClient/baseApi'
import * as htmlToImage from 'html-to-image';

const toastr = require('toastr')


export default function BookSettings ({ bookTitle, bookCoverSrc, saveTitle, bid, preventEnter, tglbtn, designId }) {
  const [bookCover, setBookCover] = React.useState(bookCoverImg); 
  const [canvaApi, setCanvaApi] = React.useState({}); 
  const { navigate, t } = useI18next();

  React.useEffect(() => {
    const addScript = (url, callback) => {
      const script = document.createElement("script")
      script.src = url
      script.async = true
      script.onload = callback;
      document.body.appendChild(script)
    }

    const loadPdfJS = function () {
      console.log("PDFJS is loaded now")
   }

    const loadCover = async function() {
      if(bookCoverSrc) {
      await fetchUrl('assets', bookCoverSrc).then( res => {
        // Create an object URL from the data.
        let bookCoverSrc = URL.createObjectURL(res)
        setBookCover(bookCoverSrc)
        })
      }
    }

    const getCanvaApiObj = async () => {
      if (!window.Canva || !window.Canva.DesignButton) {
        console.log('No Canva, exiting')
        return;
      }
        
      let api = await window.Canva.DesignButton.initialize({
          apiKey: process.env.GATSBY_CANVA_API
      });
      return(api);
    }

   const loadCanva = async () => {
    // API initialization
      if (Object.keys(canvaApi).length === 0) {
        console.log('loading canva... ')
        await getCanvaApiObj().then( api => {
          if(Object.keys(api).length > 0) {
            setCanvaApi(api) 
          }
        });
      }
    }
  
    addScript("https://rawgithub.com/mozilla/pdf.js/gh-pages/build/pdf.js", loadPdfJS);
    addScript("https://sdk.canva.com/designbutton/v2/api.js", loadCanva);

    loadCover()
  
  }, [canvaApi, bookCoverSrc])

    const handleDesignOpen = (opts) => {
        let previousDesignId = opts.designId;
     //   editDesignButton.disabled = false;
      };

    const handleDesignPublish = async (opts) => {
        let previousDesignId = opts.designId;
        //save the designId for later use
        console.log('Design Id: ' + previousDesignId);

        //upload the file exportUrl to the server and attach it with bookId
        console.log('exportURL is: ' + opts.exportUrl);
        let file = opts.exportUrl;
        let user = JSON.parse(window.localStorage.getItem('user'));


        if (file.includes('pdf')) {
          window.pdfjsLib.disableStream = true;
          window.pdfjsLib.disableWorker = true;


          // Import the PDF file for print versions to a cover_print field in books table. No need to check for the role here.
          // Do this for all users. Role is taken care at the backend
          let data = {"url": opts.exportUrl, "data": { "title": "Canva_Cover_bookid_" + bid, "uploaded_by": user.id } }

          fetchUrl('post', '/files/import', data).then((res) => {
            console.log(res)
            console.log('File imported to server')
            let data = { "print_cover" : res.data.filename_disk, "canva_design_id": previousDesignId }
            console.log(data)

             fetchUrl('patch',  '/items/books/' + bid, data).then(() => {
              console.log('updated the print cover details to the database')
            })
          })


         // Asynchronous download of PDF
          var loadingTask = window.pdfjsLib.getDocument(file);
          loadingTask.promise.then(function(pdf) {
            console.log('PDF loaded');
            
            // Fetch the first page
            var pageNumber = 1;
            pdf.getPage(pageNumber).then(function(page) {
              console.log('Page loaded');
              
              var scale = 1.5;
              var viewport = page.getViewport({scale: scale});

              // Prepare canvas using PDF page dimensions
              var canvas = document.getElementById('the-canvas');
              var context = canvas.getContext('2d');

              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              };
              var renderTask = page.render(renderContext);
              renderTask.promise.then(function () {
                console.log('Page rendered');
                document.getElementById('cover-image').alt = 'New Book Cover'
                document.getElementById('cover-image').src = canvas.toDataURL()                  
              }).then(() => {
                htmlToImage.toBlob(document.getElementById('cover-image'))
                .then(function (blob) {
                //let file = new File([blob], 'cover' + bid + '.jpg', { type: 'image/jpeg' });
                const formData = new FormData();

                  formData.append('storage', 'local')
                  formData.append('filename_download', 'cover' + bid + '.jpg')
                  formData.append('file', blob);
                  // Post via axios or other transport method
                  fetchUrl('post',  '/files', formData, 'multipart/form-data').then( res1 => {
                    console.log('JPG File uploaded to server')
                    console.log(res1.data.data.id)
                    let data = { "cover" : res1.data.data.filename_disk }
        
                    fetchUrl('patch',  '/items/books/' + bid, data).then(() => {
                      console.log(data);
                      console.log('updated the ui')
                    })
                  })
                })
              });
            });
          }, function (reason) {
            // PDF loading error
            console.error(reason);
          });
        }
        else {
          let data = {"url": opts.exportUrl, "data": { "title": "Cover_bookid_" + bid, "uploaded_by": user.id } }

          fetchUrl('post', '/files/import', data).then((res) => {
            console.log(res)
            console.log('File imported to server')
            let data = { "cover" : res.data.filename_disk, "canva_design_id": previousDesignId }
            console.log(data)

             fetchUrl('patch',  '/items/books/' + bid, data).then(() => {
              console.log('updated the cover details to the database')
              document.getElementById('cover-image').alt = 'New Book Cover'
              document.getElementById('cover-image').src = opts.exportUrl 
            })
          }) 
        }
      }
    

  const createDesignButton = () => {
    let previousDesignId = designId
    if(Object.keys(canvaApi).length === 0) {
      console.log('Canva not possible')
      return;
    }
    if(previousDesignId) {
      canvaApi.editDesign({
        design: {
          id: previousDesignId,
        },
        editor: {
          fileType: "pdf",
        },
        onDesignOpen: handleDesignOpen,
        onDesignPublish: handleDesignPublish,
      });
    }
    else {
      canvaApi.createDesign({
        design: {
          type: "BookCover",
        },
        editor: {
          fileType: "pdf",
        },
        onDesignOpen: handleDesignOpen,
        onDesignPublish: handleDesignPublish,
      });
    }

  }

  const makeCoverClick = () => {
    const cover = document.getElementById('bookCover')
    cover.click()
  }

  const makeTitleClick = () => {
    const title = document.getElementById('bookTitle1');
    title.focus();
  }

  const selectAllText = () => {
    document.getElementById('editIcon').style.display = "none";

    setTimeout(
      function() {
        document.execCommand('selectAll', false, null)
      },0)
  }
  
  const uploadImage = function(e) {
    var filename = document.getElementById('bookCover').value;
    var dot_pos = filename.lastIndexOf(".");
    var ext
    if (dot_pos !== -1) {
       ext = filename.substr(dot_pos + 1).toLowerCase()
    }

	if(ext === "pdf") {
    const pdffile = e.target.files[0]
    window.pdfjsLib.disableStream = true;
    window.pdfjsLib.disableWorker = true;

      // Upload the PDF file for print versions to a cover_print field in books table. No need to check for the role here.
      // Do this for all users. Role is taken care at the backend

      const file = new FormData()
          file.append('file', pdffile)

           fetchUrl('post',  '/files', file, 'multipart/form-data').then( res => {
            console.log('File uploaded to server')
            let data = { "print_cover" : res.data.data.filename_disk }

             fetchUrl('patch',  '/items/books/' + bid, data)
          })

		var fileReader = new FileReader();  
		fileReader.onload = function() {
			var pdfData = new Uint8Array(this.result);
			// Using DocumentInitParameters object to load binary data.
			var loadingTask = window.pdfjsLib.getDocument({data: pdfData});
			loadingTask.promise.then(function(pdf) {
			  console.log('PDF loaded');
			  
			  // Fetch the first page
			  var pageNumber = 1;
			  pdf.getPage(pageNumber).then(function(page) {
				console.log('Page loaded');
				
				var scale = 1.5;
				var viewport = page.getViewport({scale: scale});

				// Prepare canvas using PDF page dimensions
        var canvas = document.getElementById('the-canvas');
				var context = canvas.getContext('2d');
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				// Render PDF page into canvas context
				var renderContext = {
				  canvasContext: context,
				  viewport: viewport
				};
				var renderTask = page.render(renderContext);
				renderTask.promise.then(function () {
          console.log('Page rendered');
          document.getElementById('cover-image').alt = 'New Book Cover'
          document.getElementById('cover-image').src = canvas.toDataURL()  
				}).then(() => {
          
          htmlToImage.toBlob(document.getElementById('cover-image'))
          .then(function (blob) {
         
          //let file = new File([blob], 'cover' + bid + '.jpg', { type: 'image/jpeg' });
            const formData = new FormData();

            formData.append('storage', 'local')
            formData.append('filename_download', 'cover' + bid + '.jpg')
            formData.append('file', blob);
          
            // Post via axios or other transport method
            fetchUrl('post',  '/files', formData, 'multipart/form-data').then( res1 => {
              console.log('JPG File uploaded to server')
              console.log(res1.data.data.id)
              let data = { "cover" : res1.data.data.filename_disk }
  
              fetchUrl('patch',  '/items/books/' + bid, data).then(() => {
                console.log(data);
                console.log('updated the ui')
              })
            })
          })
        });
			  });
			}, function (reason) {
			  // PDF loading error
			  console.error(reason);
			});
		};
		fileReader.readAsArrayBuffer(pdffile);
	}
    else {
    const newImg = e.target.files[0]
    if (newImg) {
      const img = new Image()
      img.onload = async function () {
        console.log('image onload')
        let ratio = this.height / this.width
        if ((ratio) > 1.3 && (ratio) < 1.8 && (newImg.type === 'image/png' || newImg.type === 'image/jpg' || newImg.type === 'image/jpeg')) {
          const file = new FormData()
          file.append('file', newImg)

          await fetchUrl('post',  '/files', file, 'multipart/form-data').then(async res => {
            console.log('File uploaded to server')
            let data = { "cover" : res.data.data.filename_disk }

            await fetchUrl('patch',  '/items/books/' + bid, data).then(() => {
              img.className='uploaded-book-cover' 
              img.alt='New Book Cover' 
              img.id="cover-image"
              const oldnode = document.getElementById('cover-image')
              document.getElementById('image-grid').replaceChild(img, oldnode)
              console.log('updated the ui')
            })
          })
        } else if (!(newImg.type === 'image/png' || newImg.type === 'image/jpg' || newImg.type === 'image/jpeg')) {
          toastr.error('Please upload png/jpg/jpeg/pdf and try again')
        } else {
          toastr.error('Please maintain height and width ratio nearly 1.5')
        }
      }
      img.onerror = function () {
        toastr.error('not a valid file: ' + newImg.type)
      }
      img.src = URL.createObjectURL(newImg)
    }
  }
}  


    const cvrBtnStyle1 = {
      height: 40,
      background: '#7d2ae8',
      fontSize: 15,
      borderRadius: 5,
      marginTop: '1rem'
      }

      const cvrBtnStyle = {
        height: 40,
        fontSize: 15,
        borderRadius: 5,
        background: '#FF5B39',
        marginTop: '1rem'
      }
       
    return(

        <BookSettingStyles>
        <section>
          <Container>
            <Row>
            <Col md="10">
          <div id="bookTitle1" contentEditable="true" onBlur={saveTitle} 
          onKeyPress={preventEnter} onFocus={selectAllText}
          suppressContentEditableWarning role="presentation">
            {bookTitle}
          </div>
          </Col>
          <Col md="2">
            <div id="editIcon">
              <BsPencil size="25px" onClick={makeTitleClick} style={{ cursor: 'pointer' }}/>
            </div>
          </Col>
          </Row>
          </Container>
          <hr />
          <Container>
            <Row>
              <Col md="6">
              <div className='upload-book-cover-box' title='Click to Upload Image'>
            <input type='file' id='bookCover' accept='application/pdf,image/*' 
              onChange={uploadImage} />
            <div onClick={makeCoverClick} onKeyDown={makeCoverClick} role="presentation" id="image-grid">
              <img className='uploaded-book-cover' alt='Book Cover' src={bookCover} id="cover-image" />
              <canvas id="the-canvas" style={{display: 'none'}}></canvas>
              </div>
          </div> 
              </Col>
              <Col md="6" style={{paddingInlineStart: '10px', paddingInlineEnd: '10px' }}>
              <Row >
              <div className="custom-txt">
              <br /> 
                <p> {t('app7')}</p>
                  <p><em>{t('app17')}:</em></p>
                  <p><em>{t('app9')} (px):</em> 512 X 800</p>
                  <p><em>{t('app10')}:</em> pdf/png/jpg/jpeg</p>
                  <p><em>{t('app8')}:</em> &lt; 5MB</p>
                  <p></p>
               </div>
              </Row>
              <button style={cvrBtnStyle} type="submit" onClick={makeCoverClick}>
              {t('app4')}
              </button>
              <br />
               <button id="create-design" style={cvrBtnStyle1} onClick={createDesignButton}>{t('app5')}</button>
              {/* <button id="edit-design" disabled={true}>Edit design</button>
              <button id="download-design" disabled={true}>Download design</button> */}
              </Col>
            </Row>       
          </Container>
              <hr />
          <button className="linkStyle2" onClick={() => tglbtn("btnDnld")}><BsDownload />&nbsp;&nbsp;{t('home12')}</button>
              <hr />
              <a className="linkStyle2" href="https://help.magicauthor.com/how-to-guides/book-wizard" target="_blank" rel="noopener noreferrer"><FiHelpCircle />&nbsp;&nbsp;{t('link13')} <BiLinkExternal /></a>
        </section>
        </BookSettingStyles>

    );
  
}