// Step 1: Import React
import React, { useState, useEffect, useRef } from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';
import PricingStyles from '../styles/PricingStyles';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Row, Col } from "reactstrap";
import { FcCheckmark } from 'react-icons/fc';
import { fetchUrl } from '../apiClient/baseApi';
import useAuth from "../hooks/useAuth"

// Step 2: Define your component
const PricingPage = () => {
    const { navigate, t } = useI18next();

    const [curr, setCurr] = useState('USD')
    const [planloaded, setPlanloaded] = useState(false)
    const planRef = useRef()
    const { isAuthenticated } = useAuth()
    const [user, setUser] = useState([]); 

    let priceOptions = (<option value="USD">USD</option>)
    let planOptions = [{"name": "pro", "price": {"USD": 30}, "frequency" : "once", "discount": 0},
        {"name": "super", "price": {"USD": 20}, "frequency" : "yearly", "discount": 16}]
    const [priceOpt, setPriceOpt] = useState(priceOptions);
    const { plans } = planRef.current || { plans : planOptions }
    
    const makePurchase = function(role) {
        if (!isAuthenticated) {
            navigate('/login', { state: { redirect : 'pricing' }});
        }
        else {
            let priceId
            let mode
            let curr_key
            let data

            if (role === 'super') {
                let ele = document.getElementById('monthly')
                if(window.getComputedStyle(ele).display === "none") 
                {   
                    curr_key = curr + '_y'
                }
                else 
                {
                    curr_key = curr + '_m'
                }
                priceId = searchPlan('super').stripe_price_id[curr_key]
                mode = 'subscription'
            }
            else if (role === 'pro') {
                priceId = searchPlan('pro').stripe_price_id[curr]
                mode = 'payment'
            }

            if (user.stripe_customer_id) {
                data = {'role' : role, 'priceId': priceId, 'mode': mode, 'customer': user.stripe_customer_id, 'email': user.email}
            }
            else {
                data = {'role' : role, 'priceId': priceId, 'mode': mode, 'email': user.email}
            }
            fetchUrl('post', "/create-checkout-session", data)
            .then(res => {
                window.open(res.stripeUrl, "_self");
              });

        }
    }

    const searchPlan = function (name) {
         let match = plans.find(list => list.name === name);
        
         if (name && match) {
           return (name && match)
         }
         else {
           return ""
         }
      }

    const showSymbol = function (currency) {
        switch (currency) {
            case 'USD': 
                return '$';
            case 'EUR':
                return '€';
            case 'INR':
                return '₹';
            default:
                return '$';
        }
        
    }

    useEffect(() => {
        const plan_tbl = process.env.GATSBY_PLANS

        fetchUrl('get', '/items/' + plan_tbl).then(res => {
            planRef.current = { plans : res.data }
            setPlanloaded(true)
        })
    }, []);


    useEffect(() => {
        if(planloaded) {
        const searchPlans = function (name) {
            let match = plans.find(list => list.name === name);
            
             if (name && match) {
               return (name && match)
             }
             else {
               return ""
             }
          }
        let price = searchPlans('pro').price
        let currList = Object.keys(price).map((k) => {
           return (
            <option key={k} value={k}>{k}</option> 
           )
         }, this); 
         setPriceOpt(currList)
        }
    }, [plans, planloaded]);


  useEffect( ()=>{
    if (isAuthenticated) {
      setUser(JSON.parse(window.localStorage.getItem('user')));
      let def_cur = JSON.parse(window.localStorage.getItem('user')).pref_curr
      if (def_cur) {
          setCurr(def_cur.toUpperCase());
      }
    }
   }, [isAuthenticated])

  return (
        <PricingStyles>
                <title>Plans and Pricing</title>
             <Row style={{textAlign: 'center'}}>
                <Col md="6"> 
                <Row>
                <Col md="6">
              <p> {t('app36')} =&gt; </p>
                </Col>
                <Col md="6">
                <select name="curType" onChange={(event) => setCurr(event.target.value)} value={curr}>
                    {priceOpt}
                </select>
                </Col>   
                </Row>              
                    <div id="flexy" className="flex">
                    <div className="price-box">
						<span className="annually">
                            <div className="plan-cost"><span className="plan-price">{showSymbol(curr)}{searchPlan('super').price[curr + '_y']}</span><span className="plan-type">/{t('year')}
                        </span></div>
						</span>
                    </div>
                    </div>
                    <button onClick={() => makePurchase('super')} >{t('price32')}</button>
                    <p></p>
                            </Col>
                    <Col md="6">
                    <ul className="plan-features">
                    <li><p><strong><FcCheckmark size={22}/> {t('price15')}</strong></p></li>
                    <li><p><strong><FcCheckmark size={22}/> {t('price18')}</strong></p></li>
                    <li><p><strong><FcCheckmark size={22}/> {t('price19')}</strong></p></li>
                    <li><p><strong><FcCheckmark size={22}/> {t('price6')}</strong></p></li>
                    <li><p><strong><FcCheckmark size={22}/> {t('price21')}</strong></p></li>
                    <li><p><strong><FcCheckmark size={22}/> {t('price25')}</strong></p></li>
                    </ul>
                    </Col>
             </Row>
        </PricingStyles>
    )
}

// Step 3: Export your component
export default PricingPage
