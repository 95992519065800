// Step 1: Import React
import React, { useState, useEffect, useRef } from "react";
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Row, Col } from "reactstrap";
import { fetchUrl } from '../apiClient/baseApi';
import useAuth from "../hooks/useAuth";
import ReactModal from 'react-modal';
import Loader from './Loader';
const toastr = require('toastr')



// Step 2: Define your component
const PubPage = (props) => {
    const { t } = useTranslation();

    const [curr, setCurr] = useState('USD')
    const [planloaded, setPlanloaded] = useState(false)
    const planRef = useRef()
    const { isAuthenticated } = useAuth()
    const [user, setUser] = useState([]); 
    const [load, setLoad] = React.useState(false)

    const cont = {
      title: 'Author',
      name: '',
      bio: ''
    }
    const [con, setCon] = useState(cont)
    const [con1, setCon1] = useState(cont)
    const [desc, setDesc] = useState('')
    const [genres, setGenres] = useState('')
    const [price, setPrice] = useState(0)
    const [adult, setAdult] = useState(0)


    let priceOptions = (<option value="USD">USD</option>)
    let planOptions = [{"name": "pro", "price": {"USD": 30}, "frequency" : "once", "discount": 0},
        {"name": "super", "price": {"USD": 20}, "frequency" : "yearly", "discount": 16}]
    const [currOptions, setCurrOptions] = useState(priceOptions);
    const { plans } = planRef.current || { plans : planOptions }

    useEffect(() => {
        const plan_tbl = process.env.GATSBY_PLANS

        fetchUrl('get', '/items/' + plan_tbl).then(res => {
            planRef.current = { plans : res.data }
            setPlanloaded(true)
        })
    }, []);


    useEffect(() => {
        if(planloaded) {
        const searchPlans = function (name) {
            let match = plans.find(list => list.name === name);
            
             if (name && match) {
               return (name && match)
             }
             else {
               return ""
             }
          }
        let price = searchPlans('pro').price
        let currList = Object.keys(price).map((k) => {
           return (
            <option key={k} value={k}>{k}</option> 
           )
         }, this); 
         setCurrOptions(currList)
        }
    }, [plans, planloaded]);

    useEffect( ()=>{
      if (props.data) {
        let book = props.data
        if(book.summary) {
          setDesc(book.summary)
        }
        if(book.genres) {
          setGenres(book.genres)
        }
        if(book.contributors) {
          setCon1({
            title: book.contributors.title,
            name: book.contributors.name,
            bio: book.contributors.bio
          })
        }

        if (book.price_currency) {
          setCurr(book.price_currency.toUpperCase());
        } 

        if (book.price_before_tax) {
          setPrice(book.price_before_tax)
        }

      }
    }, [props.data])

  useEffect( ()=>{
    if (isAuthenticated) {
        let usr = JSON.parse(window.localStorage.getItem('user'))
        let name
        setUser(usr);

        if (usr.description) {
          setCon({
            bio: usr.description
          })
        }

        if (usr.first_name) {
           name = usr.first_name
        }

        if (usr.last_name) {
          name = name + usr.last_name
        }

        setCon({ name: name })
     
       
    }
   }, [isAuthenticated])

   const handleSubmit = async (event) => {
    event.preventDefault()
    var data = { }
    if (con1.name) {
      data["contributors"] = con1
    }
    if (desc) {
      data["summary"] = desc
    }

    if (genres) {
      data["genres"] = genres
    }

      data["min_age_for_adults"] = adult
      data["price_before_tax"] = price
      data["price_currency"] = curr
    

    if (Object.keys(data).length !== 0) {
      setLoad(true)
      try {
        fetchUrl('patch', '/items/books/' + props.data.id, data).then(() => {
          let email_data = { "subject" : "Request for Google Upload", 
                              "email" : user.email, 
                              "html" : "<p>title is: " + props.data.title + "</p><p>bid: " + 
                                        props.data.id + "</p><p> uid: " + user.id + "</p>"}

          fetchUrl('post', '/email-service', email_data).then(() => {
            toastr.success('Awesome. We got your details. Will keep you posted via email')
          })
        })
      } catch(e){
        toastr.error('Seems there is some glitch. Please try again later')
      }
      setLoad(false)
      document.getElementById('pubModal').style.display = 'none'

    }
  }

  return (<>
      <form onSubmit={handleSubmit}>
                  <Row style={{padding: "5px"}}>
                    <h4>{t('app19')}</h4>
                    <p></p>
                    <Col lg="6" md="6">
                    <div >
                        <p>{t('app20')}: { props.data.title } </p>
                        </div>
                    </Col>
                    <Col lg="6" md="6">
                    <div >
                        <p>{t('app21')}: { props.data.language.name }</p>
                    </div>
                    </Col>

                    </Row>
                    <Row style={{padding: "5px"}}>
                    <Col lg="12" md="12">
                        <textarea placeholder={t('app22')} rows="2" style={{width: "100%"}}
                         value={desc}
                         onChange={e => {
                          setDesc(e.target.value)
                        }} required />
                      </Col>
                    </Row>
                    <Row style={{padding: "5px"}}>
                      <Col lg="2" md="2">
                        <p >{t('app23')}: </p>
                      </Col>
                    <Col lg="10" md="10">
                      
                        <input placeholder={t('app24')} style={{width: "100%"}}
                         id="genres" value={genres}
                         onChange={e => {
                          setGenres(e.target.value)
                        }} required />
                      
                      </Col>
                    </Row>
                    <Row style={{padding: "5px"}}>
                          <Col md="8">
                            {t('app25')}
                          </Col>
                          <Col md="2">
                            <input name="optionsCheckboxes" type="radio" value="no" onChange={() => {
                              setAdult(0)
                            }} checked={adult === 0} /> 
                            <label htmlFor="no">{t('app26')}</label>
                            </Col>
                          <Col md="2">
                            <input name="optionsCheckboxes" type="radio" value="yes" onChange={() => {
                              setAdult(16)
                            }} checked={adult === 16} /> 
                            <label htmlFor="yes">{t('app27')}</label>

                          </Col>    
                    </Row>
                   {/*  <Row>
                    <Col lg="6" md="6">
                    <div style={{padding: "3px"}}>
                    <label style={{padding: "5px"}}>
                      Contributor:&nbsp;&nbsp;
                  <select name="contributor" id="cTitle" value={con.title} onChange={e => {
                          setCon({ title: e.target.value })
                        }}  >
                      <option key="author" value="Author">Author</option>
                      <option key="editor" value="Editor">Editor</option>
                      <option key="illustrator" value="Illustrator">Illustrator</option>
                      <option key="publisher" value="Publisher">Publisher</option>
                    </select>
                  </label>
                        </div>
                 </Col>
                 <Col lg="6" md="6">
                    <div style={{padding: "3px"}}>
                        <input placeholder="Name of the Contributor" type="text"  
                        id="cName"
                        value={con.name} onChange={e => {
                          setCon({ name: e.target.value })
                        }}  />
                        </div>
                 </Col>
                 <Col lg="12" md="12">
                      <div style={{padding: "5px"}}>
                      <textarea placeholder="Contributor biography" rows="2" style={{width: "100%"}}
                         id="cBio"
                         value={con.bio} onChange={e => {
                          setCon({ bio: e.target.value })
                        }} />
                      </div>
                </Col>
                </Row> */}
                 <Row  style={{padding: "5px"}}>
                    <Col lg="3" md="3">
                   <p>{t('app28')}: <strong>{con.name}</strong></p>
                 </Col>
                 <Col lg="3" md="3">
                   <Link to="/app/account">{t('app29')}</Link>
                 </Col>
                 <Col lg="12" md="12">
                      {con.bio}
                </Col>
                </Row>
                <Row style={{padding: "5px"}}>
                    <Col lg="6" md="6">
                    <label>
                    {t('app30')}:&nbsp;&nbsp;
                  <select name="contributor" id="c1Title" value={con1.title} onChange={e => {
                          setCon1({ title: e.target.value })
                        }}  >
                      <option key="author" value="Author">{t('app28')}</option>
                      <option key="editor" value="Editor">{t('app31')}</option>
                      <option key="illustrator" value="Illustrator">{t('app32')}</option>
                      <option key="publisher" value="Publisher">{t('app33')}</option>
                    </select>
                  </label>
                 </Col>
                 <Col lg="6" md="6">
                        <input placeholder={t('app34')} type="text"  
                        id="c1Name"
                        value={con1.name} onChange={e => {
                          setCon1({ name: e.target.value })
                        }}  />
                 </Col>
                 <p></p>
                 <Col lg="12" md="12">
                      <textarea placeholder={t('app35')} rows="2" style={{width: "100%"}}
                         id="c1Bio"
                         value={con1.bio} onChange={e => {
                          setCon1({ bio: e.target.value })
                        }} />
                </Col>
                </Row>
                <Row style={{padding: "5px"}}>
                  <Col lg="6" md="6">
                  <label>
                  {t('app37')}
                  <select name="prefCurr" onChange={e => {
                          setCurr(e.target.value)
                        }}  value={curr}>
                      <option>{t('app36')}</option>
                      {currOptions}
                    </select>
                  </label>
                  </Col>

                  <Col lg="3" md="3">
                    <div style={{padding: "3px"}}>
                        <input placeholder={t('app37')} type="number" 
                        onChange={e => {
                          setPrice(e.target.value)
                        }}  value={price} required />
                        </div>
                 </Col>
                   </Row>
                    <Row style={{padding: "5px"}}>
                    <Col lg="4" md="4"></Col>
                    <Col lg="4" md="4">
                      <p></p>
                      <button  type="submit" className="ma-btn">{t('app38')}</button>
                      </Col>
                      </Row>
                    </form> 
                    <ReactModal 
                        isOpen={load}
                        contentLabel="Content Loading"
                        onRequestClose={()=> {setLoad(false)}}
                        className="react-confirm-alert-body"
                        overlayClassName="react-confirm-alert-overlay"
                    >
                      <Loader />
                    </ReactModal>
                    </>
    )
}

// Step 3: Export your component
export default PubPage
